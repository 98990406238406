import {CallToActionDivider} from './banner/divider';
import {callToActionTitleOneClasses, callToActionTitleTwoClasses} from './banner/title-classes';
import {CallToActionButton} from './buttons';
import MarkdownContent from './markdown-content';

const CenteredCallToAction = ({call_to_action_title, call_to_action_secondary_title, call_to_action_paragraph, route, call_to_action_button_text = 'Read More'}:
  { call_to_action_title: string, call_to_action_secondary_title: string, call_to_action_paragraph: string, route: string, call_to_action_button_text?: string }) => (
  <div className="py-12 text-center text-gray-theme md:w-1/2 w-full mr-auto ml-auto px-4 sm:px-6 md:px-0">
    <h2 className={callToActionTitleOneClasses}>{call_to_action_title}</h2>
    <CallToActionDivider/>
    <h3 className={callToActionTitleTwoClasses}>{call_to_action_secondary_title}</h3>
    <MarkdownContent>
      {call_to_action_paragraph}
    </MarkdownContent>
    <CallToActionButton route={route}>
      {call_to_action_button_text}
    </CallToActionButton>

  </div>
);


export default CenteredCallToAction;