import {mdiArrowLeft, mdiArrowRight} from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import type {GetStaticProps, NextPage} from 'next';
import NextImage from 'next/image';
import Link from 'next/link';
import {useContext, useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick.css';
import {BannerDivider} from '../components/banner/divider';
import {titleOneClasses, titleTwoClasses} from '../components/banner/title-classes';
import {LearnMoreButton} from '../components/buttons';
import CenteredCallToAction from '../components/centered-call-to-action';
import ImageWithBlur from '../components/image-with-blur';
import NewsSection from '../components/news-section';
import PageWidthConstraint from '../components/pagewidthconstraint';
import ServicesSection from '../components/services-section';
import {waitload} from '../components/waitload';
import {fetchAPI} from '../lib/api';
import {getStrapiMedia} from '../lib/media';
import {HomePageBanner, HomePageContentProps} from '../types/page-types';
import {StrapiImage} from '../types/strapi-types';
import {MetaTagContext} from './_app';

const Home: NextPage<HomePageContentProps> = ({homepage, homepageBanner, services, newsData}: HomePageContentProps) => {
  const sliderRef = useRef<Slider | null>(null);
  const [iconImages, setIconImages] = useState<StrapiImage[] | []>([]);
  const [activeIcon, setActiveIcon] = useState<number>(0);

  const {setPageTitle, setMetaDescription, setOtherMetaTags} = useContext(MetaTagContext);
  useEffect(() => {
    const homePageAttributes = homepage.data.attributes;
    if (homePageAttributes.default_meta_tags?.page_title) {
      setPageTitle(homePageAttributes.default_meta_tags.page_title);
    }
    if (homePageAttributes.default_meta_tags?.page_title) {
      setMetaDescription(homePageAttributes.default_meta_tags.meta_description);
    }
    setOtherMetaTags(homePageAttributes.other_meta_tags);
  }, []);

  const next = () => {
    if (activeIcon < iconImages.length - 1) {
      setActiveIcon(currentIcon => currentIcon + 1);
    } else {
      setActiveIcon(0);
    }
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (activeIcon === 0) {
      setActiveIcon(iconImages.length - 1);
    } else {
      setActiveIcon(currentIcon => currentIcon - 1);
    }
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 500,
    dotsClass: 'relative text-left w-full bg-transparent ',
    appendDots: (dots: any) => (
      <PageWidthConstraint>
        <div className="w-full md:absolute flex md:justify-start justify-center list-none ml-auto mr-auto -mt-16">
          {
            iconImages?.map((value, index) => (
              <Link key={index} href={`/services/genetics/${value?.type}`}>
                <button className={cls('bg-gray-100 hover:bg-gray-200 mr-1 px-4 pt-4 pb-2')}>
                  <NextImage
                    src={getStrapiMedia(iconImages[index])}
                    width="35px"
                    height="35px"
                    alt="icon"
                  />
                </button>
              </Link>))
          }
          <button onClick={previous} style={{width: '67px'}} className="hover:bg-gray-300 bg-gray-400 mr-1 px-4 pt-1 pb-2">
            <Icon path={mdiArrowLeft} size={1.5} color="white"/>
          </button>
          <button onClick={next} style={{width: '67px'}} className="hover:bg-gray-300 bg-gray-400 mr-1 px-4 pt-1 pb-2">
            <Icon path={mdiArrowRight} size={1.5} color="white"/>
          </button>
        </div>
      </PageWidthConstraint>
    ),
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    if (homepageBanner.data.length > 0) {
      setIconImages(homepageBanner.data.map((item: HomePageBanner) => ({...item.attributes.banner_icon, type: item.attributes.type})));
    }
  }, [homepageBanner]);

  return (
    <div className="-mt-64 z-10">
      <Slider ref={slider => (sliderRef.current = slider)} {...settings} className="h-screen w-full relative">
        {homepageBanner.data.map((item: any) => (
          <div key={item.id} className="h-screen w-full relative">
            <ImageWithBlur key={item.attributes.banner_image.data?.id} image={item.attributes.banner_image} layout="fill"/>
          </div>
        ))
        }
      </Slider>
      <PageWidthConstraint>
        <div className={cls('absolute md:top-1/2 top-2/3 lg:short:top-1/4 flex flex-col md:ml-0 -ml-4')}>
          <div className="md:w-3/5 w-full">
            <p className={cls(titleOneClasses, 'w-1/2')}>{homepageBanner.data[activeIcon].attributes.title_one}</p>
            <BannerDivider/>
            <div className={cls(titleTwoClasses, 'md:px-0 px-8')}>{homepageBanner.data[activeIcon].attributes.title_two}</div>
            <div className="w-full sm:block flex justify-center">
              <LearnMoreButton href={homepageBanner.data[activeIcon].attributes.button_link ? homepageBanner.data[activeIcon].attributes.button_link : ''}>
                {homepageBanner.data[activeIcon].attributes.button_title ? homepageBanner.data[activeIcon].attributes.button_title : 'Learn more'}
              </LearnMoreButton>
            </div>
          </div>
        </div>
      </PageWidthConstraint>
      <div className="py-16">
        <CenteredCallToAction call_to_action_title={homepage.data.attributes.call_to_action_title}
                              call_to_action_secondary_title={homepage.data.attributes.call_to_action_secondary_title}
                              call_to_action_paragraph={homepage.data.attributes.call_to_action_paragraph}
                              route="/about"/>
      </div>
      <ServicesSection {...services}/>
      <NewsSection newsSection={homepage} facebookData={newsData}/>
    </div>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const [homepageBannerRes, homepageRes, servicesRes, newsRes] = await Promise.all([
    fetchAPI('/home-banner-images', {populate: ['banner_image', 'banner_icon']}),
    fetchAPI('/home-page', {populate: '*'}),
    fetchAPI('/services-content', {populate: '*'}),
    fetchAPI('/news-pages', {populate: '*', sort: 'publish_date:DESC'}),
    waitload(),
  ]);
  return {
    props: {
      homepage: homepageRes,
      homepageBanner: homepageBannerRes,
      services: servicesRes,
      newsData: newsRes,
    },
  };
};

export default Home;
