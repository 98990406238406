import React, {useEffect, useState} from "react";
import {ServicesContent, TemporarySection} from "../types/page-types";
import GridSection from "./grid-section";
import {gridBorderColors, gridColors} from "./color-map";

const ServicesSection = (services: ServicesContent) => {
    const servicesAttributes: any = services.data.attributes;
    const [sections, setSections] = useState<TemporarySection[]>([]);
    const links: string[] = [
        "/services/genetics",
        "/services/r-and-d",
        "/services/sales-and-service",
    ];
    useEffect(() => {
        let serviceContentArray: TemporarySection[] = [];
        const keys: string[] = ['one', 'two', 'three']
        keys.forEach(function ( key, i) {
            let element: TemporarySection = {
                title: servicesAttributes[`service_${key}_title`],
                text: servicesAttributes[`service_${key}_text`],
                image: servicesAttributes[`service_${key}_image`],
                link: links[i],
                borderColor: gridBorderColors[i],
                secondaryTitleSize: 'text-xl',
                color: gridColors[i]
            };
            serviceContentArray.push(element);
        });
        setSections(serviceContentArray);
    }, []);

    return (
        <GridSection gridSections={sections}/>
    );
}

export default ServicesSection;