import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cls from "classnames";
import {callToActionParagraphClasses, callToActionTitleOneClasses, callToActionTitleTwoClasses} from "./banner/title-classes";
import {CallToActionDividerLeft, CustomColourDivider} from "./banner/divider";
import {CallToActionButton} from "./buttons";
import dayjs from 'dayjs';
import PageWidthConstraint from "./pagewidthconstraint";
import ImageWithBlur from "./image-with-blur";
import {HomePageAttributes, HomePageContent, NewsPage, NewsPageAttributes, NewsPages} from "../types/page-types";


const ArticleSection = ({article}: { article: NewsPage }) => {
    const articleAttributes: NewsPageAttributes = article.attributes
    return (
        <div className={cls('flex flex-col px-10 pt-2 pb-10')}>
            <div className="w-full">
                <ImageWithBlur  key={articleAttributes.image.data?.id} className='rounded-full border ml-auto mr-auto' image={articleAttributes.image} objectFit="cover" width="500"
                               height="500"/>
            </div>
            <p className={cls(callToActionParagraphClasses, 'pt-4')}>{dayjs(articleAttributes.publish_date).format('MMMM D, YYYY')}</p>
            <h6 className={callToActionTitleOneClasses}>{articleAttributes.title}</h6>
            <CallToActionDividerLeft/>
            <p className={callToActionParagraphClasses}>{articleAttributes.paragraph?.length > 125 ? articleAttributes.paragraph.substring(0, 125) + '...' : articleAttributes.paragraph}</p>
            {articleAttributes.link && <CallToActionButton className='w-44' route={articleAttributes.link || '/'}>
                Read more
            </CallToActionButton>}
        </div>
    )
}

const NewsSection = ({newsSection, facebookData} : {newsSection: HomePageContent, facebookData: NewsPages}) => {
    const newsData:  HomePageAttributes = newsSection.data.attributes
    const facebookArticles: NewsPage[] = facebookData.data
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }

    return (
        <div className='bg-gray-300 w-full'>
            <PageWidthConstraint className='ml-auto mr-auto md:space-x-20 md:flex'>
                <div className='md:w-1/2 w-full h-full md:mt-auto md:mb-auto md:pt-0 pt-10'>
                    <h4 className={callToActionTitleOneClasses}>News Feed</h4>
                    <CallToActionDividerLeft/>
                    <h4 className={callToActionTitleTwoClasses}>{newsData.news_title}</h4>
                    <p className={callToActionParagraphClasses}>{newsData.news_paragraph}</p>
                    {newsData.news_link && <CallToActionButton className='w-44' route={newsData.news_link}>
                        Read more
                    </CallToActionButton>}
                </div>
                <div className='md:w-1/2 w-full pt-20'>
                    <div className="pl-10">
                        <h5 className="text-lg font-bold text-cyan-logo">From Facebook</h5>
                        <CustomColourDivider className='border-cyan-logo'/>
                    </div>
                    <Slider className='md:mx-0 mx-4' {...settings} >
                        {facebookArticles.map((article: NewsPage, index: number) =>
                            <ArticleSection article={article} key={index}/>
                        )}
                    </Slider>
                </div>

            </PageWidthConstraint>
        </div>
    );
}

export default NewsSection;